<template>
  <section class="success">
    <popup-base
        v-show="wantToMakeOffer"
        class="popo md:pt-10 md:pb-4 w-full"
        @clickOnBackground="logState"
    >
      <div class="cardinal mr-auto ml-auto p-5">
        <div
            class="success-container h-full pt-6"
        >
          <div class="text-left flex pl-6 pr-6">
            <div
                class="text-c18 w-4/5"
            >
              Nouvelle dépense
            </div>
            <div class="w-1/5 flex justify-end">
              <icon
                  :data="icons.fermer"
                  height="30"
                  width="30"
                  class="cursor-pointer"
                  original
                  @click="fermer"
              />
            </div>
          </div>

          <div class="text-c18 font-c4 mt-6 bg-white p-6 pb-12">

            <div>
              <div class="text-c14">Date</div>
              <datepicker class="mb-4 mt-1" @info="backDate"/>

              <div class="text-c14 mt-6 mb-1">Founisseur</div>
              <multiselect
                  :option="optionSupplier"
                  v-if="optionSupplier.length > 0"
                  @info="retourSupplier"
                  :writeOption="true"
                  background="#fff"
              />

              <div class="text-c14 mt-6 mb-1">Service</div>
              <multiselect
                  :option="optionService"
                  v-if="optionService.length > 0"
                  @info="retourService"
                  background="#fff"
                  value-t="Sélectionner"
              />

              <div class="text-c14 mt-6 mb-1">Ref. Facture/Devis </div>
              <inputo @info="backRef"/>

              <div class="text-c14 mt-6 mb-1">Caisse/Banque débitée</div>
              <multiselect
                  background="#fff"
                  :option="optionDestinataire"
                  v-if="optionDestinataire.length > 0"
                  @info="retourDestinataire"
                  value-t="Destinataire"
              />

              <div class="text-c14 mt-6 text-left bloc1 p-5 flex items-center">
                <div class="">
                  <div class="">
                    <span class="text-yell">Montant disponible</span>
                    <span class="">
                      : {{ lastAmount.toLocaleString() }} F
                    </span>
                  </div>
                </div>
              </div>

              <div class="text-c14 mt-6 mb-1">Moyen de paiement *</div>
              <multiselect
                  v-if="payementType !== null"
                  :option="payementType"
                  @info="retourType"
                  background="#fff"
                  :value-t="payementValue"
              />

              <div v-if="valuePayement === 'Chèques'">
                <div class="text-c14 mt-6 mb-1">Numéro de chèque </div>
                <inputo @info="backCheque"/>
              </div>


              <div v-if="valuePayement === 'Virement bancaire'">
                <div class="text-c14 mt-6 mb-1">Numéro de bordereau</div>
                <inputo @info="backBordereau"/>
              </div>


              <div class="text-c14 mt-6 mb-1">Montant </div>
              <inputo type="number" @info="backAmount"/>

              <div v-if="valuePayement !== 'Espèces'">
                <upoader background="rgba(242, 245, 249, 0.5)" class="mt-6" value="Pièce jointe de paiement" height="50px" @info="backPaiement"/>

                <upoader background="rgba(242, 245, 249, 0.5)" class="mt-6" value="Pièce jointe de facture" height="50px" @info="backFacture"/>
              </div>

              <upoader background="rgba(242, 245, 249, 0.5)" class="mt-6" value="Pièce jointe" height="50px" @info="backFacture" v-if="valuePayement === 'Espèces'"/>

              <div class="text-c14 mt-6 mb-1">Notes </div>
              <textare height="132.53px" radius="5px" @info="retourNote" />
            </div>

            <div
                v-if="error !== null"
                class="mt-6 text-red text-c14"
            >
              {{ error }}
            </div>

            <div class="flex mt-10">
              <div class="w-full flex">
                <buton
                    class="w-1/2"
                    label="Annuler"
                    radius="5px"
                    height="54px"
                    background="#DDDDDD"
                    color="black"
                    :charge="charge"
                    @info="fermer"
                />
                <buton
                    class="w-1/2 ml-6"
                    label="Enregistrer"
                    radius="5px"
                    height="54px"
                    :charge="charge"
                    @info="continuer"
                />
              </div>
            </div>
          </div>

        </div>
      </div>
    </popup-base>
  </section>
</template>

<script>
import PopupBase from '../helper/add/popupfat'
import buton from '../helper/add/button'
import alerte from '../../assets/icons/error.svg'
import fermer from '../../assets/icons/fermer.svg'
import arrow from '../../assets/icons/arrow.svg'
import transfere from '../../assets/icons/transfere.svg'
import multiselect from '../helper/form/multiselect'
import datepicker from '../helper/form/datePicker'
import textare from '../helper/form/textarea'
import inputo from '../helper/form/input'
import http from "../../plugins/https"
import apiroutes from "../../router/api-routes"
import upoader from '../helper/form/uploader'

export default {
  name: 'Success',
  components: {
    PopupBase,
    buton,
    multiselect,
    textare,
    datepicker,
    inputo,
    upoader
  },

  props: {
    activation: {
      type: Boolean,
      default: false
    },
    donne: {
      type: Object,
      default: null
    },
    cheque: {
      type: Array,
      default: null
    },
    billet: {
      type: Array,
      default: null
    }
  },

  data () {
    return {
      donnes: null,
      wantToMakeOffer: false,
      icons: {
        fermer,
        arrow,
        transfere,
        alerte
      },

      error: null,
      charge: false,
      userData: [],
      optionDestinataire: [],
      destinataire: null,
      payementType: null,
      valuePayement: null,
      supplierData: [],
      optionSupplier: [],
      valueSupplier: null,
      banque: [],
      optionService: [],
      valueService: null,
      response: {
        data: {}
      },
      factureFile: null,
      paiementFile: null,
      factureUrl: null,
      paiementUrl: null,
      payementValue: 'Sélectionner',
      lastAmount: 0
    }
  },

  created() {
    this.wantToMakeOffer = this.activation
    this.donnes = this.donne
    this.optionDestinataire.push('COMPTABILITÉ')
    this.getSupplier()
    this.getBank()
  },

  methods: {
    fermer () {
      this.$emit('oga', false)
    },

    backFacture (a) {
      this.factureFile = a
    },

    backPaiement (a) {
      this.paiementFile = a
      console.log(a)
    },

    backDate (a) {
      this.response.date = a
    },

    backAmount (a) {
      this.response.amount = parseInt(a)
    },

    backRef (a) {
      this.response.reference_invoice = a
    },

    backCheque (a) {
      this.response.reference_bank_check = a
    },

    backBordereau (a) {
      this.response.reference_bank_transfer = a
    },

    retourService (l) {
      this.response.categories = [l]
    },

    // Vérification
    async continuer() {
      if (this.response.date && this.response.amount && this.response.paymentType && this.response.supplier && this.factureFile !== null) {
        await this.saveFile(this.factureFile, 'facture')

        // TYPE !== ESPECES
        if (this.paiementFile) {
          await this.saveFile(this.paiementFile, 'paye')
        }

        if (this.response.reference_join_url || this.response.payment_join_url) {
          this.saveExpence()
        }
      } else {
        this.error = 'Veuillez remplir le formulaire pour continuer'
      }
    },


    //Ici j'enregistre les fichiers et je récupère des url
    async saveFile (file, type) {
      this.charge = true
      let data = new FormData()
      data.append('files', file)
      let config = {
        header : {
          'Content-Type' : 'multipart/form-data'
        }
      }

      await http.post(apiroutes.baseURL + apiroutes.upload, data, config)
          .then(response => {
            console.log(response)
            if (type === 'facture') {
              this.response.reference_join_url = response[0].url
            } else {
              this.response.payment_join_url = response[0].url
            }
          })
          .catch(error => {
            console.log(error)
          })
    },

    async saveExpence () {
      console.log('body')
      console.log(this.response)
      this.charge = true
      await http.post(apiroutes.baseURL + apiroutes.makeExpense, this.response)
          .then(response => {
            this.charge = false
            console.log('success')
            console.log(response)
            this.$emit('oga', response.pdf)
          })
          .catch(error => {
            this.charge = false
            console.log(error)
          })
    },

    retourDestinataire (answer) {
      if (answer !== 'Destinataire'){
        this.destinataire = answer

        if (answer === 'COMPTABILITÉ'){
          this.payementType = null
          this.lastAmount = this.donnes.cash
          this.response.wallet = this.donnes.wallet.id
          this.payementValue = 'Espèces'
          this.payementType = ['Espèces']
        }

        for (let item in this.banque){
          if (this.banque[item].bankName === answer){
            this.response.wallet = this.banque[item].id
            this.lastAmount = this.banque[item].amount
            this.payementType = ['Chèques', 'Virement bancaire']
          }
        }
      }
    },

    retourType (x) {
      this.valuePayement = x
      if (x === 'Espèces') {
        this.response.paymentType = 'CASH'
      }
      if (x === 'Chèques') {
        this.response.paymentType = 'BANK_CHECK'
      }
      if (x === 'Virement bancaire') {
        this.response.paymentType = 'BANK_TRANSFER'
      }
    },

    retourNote (answer) {
      this.response.note = answer
    },

    logState (answer) {
      this.wantToMakeOffer = answer
      this.fermer()
    },

    retourSupplier (s) {
      this.optionService = []
      this.valueSupplier = s
      for (let item in this.supplierData) {
        if (this.supplierData[item].name === s){
          this.response.supplier = this.supplierData[item].id

          const service = this.supplierData[item].services
          for (let i in service) {
            if (service[i] === '&') {
              console.log(service.substr(0, i + 1))
              this.optionService.push(service.substr(0, i + 1))
            }
          }
          if (this.optionService.length === 0) {
            this.optionService.push(service)
          }
        }
      }
    },

    getSupplier() {
      http.get(apiroutes.baseURL + apiroutes.getSupplier)
          .then(response => {
            this.charge = false
            console.log('suplier')
            console.log(response)
            this.supplierData = response

            for (let item in response) {
              this.optionSupplier.push(response[item].name)
            }
          })
          .catch(error => {
            this.charge = false
            console.log(error)
          })
    },

    getBank() {
      http.get(apiroutes.baseURL + apiroutes.allBank)
          .then(response => {
            this.charge = false
            console.log('bank')
            console.log(response)
            this.banque = response.banks
            for (let item in this.banque) {
              this.optionDestinataire.push(this.banque[item].bankName)
            }
          })
          .catch(error => {
            this.charge = false
            console.log(error)
          })
      },
    }
}
</script>

<style lang="scss"  scoped>
@import "../../assets/styles/sass/mixin";
.success {
  z-index: 999;
}
.cardinal {
  width: 42%;
}

.moyens{
  background-color: #F5F5F5;
}

.success-container {
  font-family: $font-default;
  width: 100%;
  background-color: #F5F5F5;
  height: 100vh;
  margin: auto;
  border-radius: 12px;
}
.inputo{
  height: 40px;
  border: 1px solid #DCDCE4;
  border-radius: 4px;
}
.bloc{
  border-bottom: 1px solid #DDDDDD;
}
.bord{
  border-bottom: 1px dashed #000000
}

.bloc1{
  min-height: 40px;
  background: #FFF6EF;
  border-radius: 5px;
}
@media screen and (max-width: 700px){
  .success-container {
    width: 100%;
    height: 100vh;
  }
  .titre {
    font-size: 18px;
  }
  .bloc1 {
    width: 100%;
  }
  .bloc2 {
    width: 100%;
    font-size: 18px;
  }
  .button {
    width: 100%;
    font-size: 16px;
  }
  .cardinal {
    width: 100%;
  }
}

@media screen and (max-width: 1024px) and (min-width: 770px){
  .cardinal{
    width: 65%;
  }
  .titre{
    font-size: 18px;
  }
  .button{
    width: 100%;
    height: 55px;
    font-size: 19px;
  }
}

@media screen and (max-width: 769px) and (min-width: 701px){
  .cardinal{
    width: 90%;
  }
  .titre{
    font-size: 18px;
  }
  .button{
    width: 100%;
    height: 55px;
    font-size: 19px;
  }
}
@media screen and (max-width: 320px){
  .cardinal{
    width: 100%;
  }
  .titre{
    font-size: 14px;
  }
  .button{
    width: 100%;
    height: 55px;
    font-size: 15px;
  }
}
</style>
