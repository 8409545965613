<template>
  <div class="text-left">
    <div class="flex mt-6">
      <div class="w-1/2 item bg-white p-6" @click="goToDetail">
        <div class="w-full">
          <div class="text-c16 text-90 w-full"> Réalisation mensuelle </div>
          <div class="text-c24 w-full font-c5" v-if="donnes.monthData.length > 0">
<!--            {{ refactorAmount(donnes.monthData[donnes.monthData.length -1].amount, '.') }}-->
            {{donnes.monthData[new Date().getMonth()] ? refactorAmount(donnes.monthData[donnes.monthData.length -1].amount, '.') : 0}}
            <span class="text-90"> FCFA</span>
          </div>
        </div>

        <div class="mt-4 pr-5">
          <apexchart width="490" height="220" type="line" :options="options" :series="series"/>
        </div>
      </div>

      <div class="w-1/2 flex ml-5">
        <div class="w-1/2 item bg-white p-6">
          <div class="flex items-center">
            <icon
                :data="icons.wallet"
                height="35"
                width="35"
                class="cursor-pointer"
                original
            />

            <div class="ml-4">
              <div class="text-c14">Caisse recette</div>
              <div class="text-c18 font-c5">
                {{ refactorAmount(donnes.cashAMount + donnes.checkAmount, '.') }}
                <span class="text-d"> FCFA</span>
              </div>
            </div>
          </div>

          <div class="mt-10">
            <div class="flex">
              <div class="w-1/3 text-c11 text-90">Espèces</div>
              <div class="w-2/3 font-c6 text-c13 text-right">
                {{ refactorAmount(donnes.cashAMount, '.') }} FCFA
              </div>
            </div>

            <div class="flex mt-6">
              <div class="w-1/3 text-c11 text-90">Chèques</div>
              <div class="w-2/3 font-c6 text-c13 text-right">
                {{ refactorAmount(donnes.checkAmount, '.') }} FCFA
              </div>
            </div>
          </div>

          <div class="mt-10">
            <div
                class="h-45 rounded-10 text-white text-c14 flex justify-center items-center cursor-pointer bg-oho-blue-clear"
              @click="goToTransfere('/transfere')"
            >
              <div>Transfert de caisse</div>
              <icon
                  :data="icons.arrow"
                  height="20"
                  width="20"
                  class="cursor-pointer ml-4"
                  color="#fff"
                  original
              />
            </div>

            <div
                class="h-45 mt-6 rounded-10 text-white text-c14 flex justify-center items-center cursor-pointer bg-violet-clear"
                @click="goToTransfere('/remise')"
            >
              <div class="text-oho-blue">Remise en banque</div>
              <icon
                  :data="icons.arrow"
                  height="20"
                  width="20"
                  class="cursor-pointer ml-4"
                  color="#5138EE"
                  original
              />
            </div>
          </div>
        </div>

        <div class="w-1/2 ml-4">
          <div class="w-full item bg-white p-6">
            <div class="flex items-center">
              <icon
                  :data="icons.mobile"
                  height="35"
                  width="35"
                  class="cursor-pointer"
                  original
              />

              <div class="ml-4">
                <div class="text-c14">Caisse Mobile Money</div>
                <div class="text-c18 font-c5">
                  {{ refactorAmount(donnes.mobileMoneyAmount, '.') }}
                  <span class="text-d"> FCFA</span></div>
              </div>
            </div>

            <div class="h-1 bg-d mt-2 w-full"></div>

            <div class="mt-4">
              <div
                  class="h-45 rounded-10 text-white text-c14 flex justify-center items-center cursor-pointer bg-oho-blue-clear"
                  @click="goToTransfere('/mobileTransfere')"
              >
                <div>Transfert MoMo</div>
                <icon
                    :data="icons.arrow"
                    height="20"
                    width="20"
                    class="cursor-pointer ml-4"
                    color="#fff"
                    original
                />
              </div>
            </div>
          </div>


          <div class="w-full item degrad p-4 mt-6">
            <div class="flex items-center">
              <div class="text-c12 text-yell font-c6 w-2/3">Historique des transferts et remises</div>

              <div class="w-1/3 text-c10 underline cursor-pointer" @click="historique">Tout afficher</div>
            </div>

            <div class="h-1 bg-d mt-4 w-full"></div>

            <div class="mt-4">
              <div class="">
                <div class="flex">
                  <div class="w-1/2 text-c11 text-90">Espèces</div>
                  <div class="w-1/2 font-c6 text-c13 text-right">{{ donnes.transferCashCount }}</div>
                </div>

                <div class="flex mt-2">
                  <div class="w-1/2 text-c11 text-90">Chèques</div>
                  <div class="w-1/2 font-c6 text-c13 text-right">{{ donnes.transferBankCount }}</div>
                </div>

                <div class="flex mt-2">
                  <div class="w-1/2 text-c11 text-90">Mobile Money</div>
                  <div class="w-1/2 font-c6 text-c13 text-right">{{ donnes.transferMomoCount }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import wallet from '../../../assets/icons/wallet.svg'
import mobile from '../../../assets/icons/mobile.svg'
import arrow from '../../../assets/icons/arrow.svg'

export default {
  name: "Index",
  components: {
  },
  props: {
    donnes: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      icons: {
        wallet,
        arrow,
        mobile
      },
      options: {
        chart: {
          id: 'vuechart-example'
        },
        xaxis: {
          categories: ['Jan', 'Fev', 'Mar', 'Avr', 'Mai', 'Jui', 'Jui', 'Aou', 'Sep', 'Oct', 'Nov', 'Dec']
        },
        stroke: {
          curve: 'smooth',
          colors: ['#06AC7E']
        },
        markers: {
          size: 4,
          colors: ['#06AC7E']
        },
      },
      series: [{
        name: 'Chiffre d\'affaire',
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      }]
    }
  },

  created () {
    // console.log('oiisidfj')
    // console.log(this.donnes)
    let data = this.donnes.monthData
    if (data.length > 0) {
      for (let item in data) {
        this.series[0].data[data[item].label] = data[item].amount
      }
    }
  },

  methods: {
    goToDetail (){
      this.$router.push('/caisseDetail')
    },

    historique () {
      this.$router.push({ path: '/historique', query: { x: this.donnes.wallet.id } })
    },

    goToTransfere (index) {
      this.$store.dispatch('saveTemporalItem', this.donnes.wallet)
      this.$store.dispatch('saveWallet', this.donnes)
      this.$router.push(index)
    },

    goto (index) {
      this.$router.push(index)
    },

    refactorAmount (a, b) {
      a = '' + a;
      b = b || ' ';
      let c = '',
          d = 0;
      while (a.match(/^0[0-9]/)) {
        a = a.substr(1);
      }
      for (let i = a.length-1; i >= 0; i--) {
        c = (d !== 0 && d % 3 === 0) ? a[i] + b + c : a[i] + c;
        d++;
      }
      return c;
    },
  },
}
</script>

<style lang="scss" scoped>
.svg-icon{
  fill: transparent;
}
.item{
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}
.degrad {
  background: linear-gradient(180deg, #F6E6C6 0%, rgba(251, 167, 5, 0) 100%);
}
</style>
