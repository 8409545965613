<template>
  <section class="success">
    <popup-base
        v-show="wantToMakeOffer"
        class="popo md:pt-10 md:pb-4 w-full"
        @clickOnBackground="logState"
    >
      <div class="cardinal mr-auto ml-auto p-5">
        <div
            class="success-container h-full pt-6"
        >
          <div class="text-left flex pl-6 pr-6">
            <div
                class="text-c18 w-4/5"
            >
              Transfert MoMo en banque
            </div>
            <div class="w-1/5 flex justify-end">
              <icon
                  :data="icons.fermer"
                  height="30"
                  width="30"
                  class="cursor-pointer"
                  original
                  @click="fermer"
              />
            </div>
          </div>


          <div class="text-c18 font-c4 mt-6 bg-white p-6 pb-12">

            <div>
              <div class="text-c14">Date du transfert</div>
              <datepicker class="mb-4 mt-1" @info="backDate"/>

              <div class="text-c14 mt-6 mb-1">Banque</div>
              <multiselect background="#fff" :option="optionDestinataire" v-if="optionDestinataire.length > 0" @info="retourDestinataire" />

              <div class="text-c14 mt-6 mb-1">Montant </div>
              <inputo type="number" @info="backAmount"/>

              <div class="text-c14 mt-6 mb-1">Reference</div>
              <inputo @info="backRef"/>

              <div class="text-c14 mt-6 mb-1">Frais de l’opérateur (%)</div>
<!--              <inputo type="number" @info="backFrais" :value-t="1"/>-->

              <div class="flex items-center inputo pl-2 text-c14 bg-f3">
                <div>{{ response.data[0].percent }}</div>
              </div>

              <div class="text-c14 mt-6 text-left bloc1 p-5 flex items-center">
                <div class="">
                  <div class="text-yell">Récapitulatif :</div>


                  <div class="mt-2">
                    <span class="text-98">Montant tranféré :</span>
                    <span class="ml-2">
                      {{ response.data[0].amount.toLocaleString() }} F
                    </span>
                  </div>

                  <div class="mt-2">
                    <span class="text-98">Frais opérateur :</span>
                    <span class="ml-2">
                      {{ ((response.data[0].amount * response.data[0].percent)/100).toLocaleString() }} F
                    </span>
                  </div>

                  <div class="mt-2">
                    <span class="text-98">Montant débité :</span>
                    <span class="ml-2">
                      {{ (response.data[0].amount + (response.data[0].amount * response.data[0].percent)/100).toLocaleString() }} F
                    </span>
                  </div>

                  <div class="mt-2">
                    <span class="text-98">Solde MoMo restant :</span>
                    <span class="ml-2">
                      : {{ (parseInt(solde) - response.data[0].amount - ((response.data[0].amount * response.data[0].percent)/100)).toLocaleString() }} F
                    </span>
                  </div>
                </div>
              </div>

              <div class="text-c14 mt-6 mb-1">Notes </div>
              <textare height="132.53px" radius="5px" @info="retourNote" />
            </div>

            <div
                v-if="error !== null"
                class="mt-6 text-red text-c14"
            >
              {{ error }}
            </div>

            <div class="flex mt-10">
              <div class="w-full flex">
                <buton
                    class="w-1/2"
                    label="Annuler"
                    radius="5px"
                    height="54px"
                    background="#DDDDDD"
                    color="black"
                    :charge="charge"
                    @info="fermer"
                />
                <buton
                    class="w-1/2 ml-6"
                    label="Enregistrer"
                    radius="5px"
                    height="54px"
                    :charge="charge"
                    @info="continuer"
                />
              </div>
            </div>
          </div>


        </div>
      </div>
    </popup-base>
  </section>
</template>

<script>
import PopupBase from '../helper/add/popupfat'
import buton from '../helper/add/button'
import alerte from '../../assets/icons/error.svg'
import fermer from '../../assets/icons/fermer.svg'
import arrow from '../../assets/icons/arrow.svg'
import transfere from '../../assets/icons/transfere.svg'
import multiselect from '../helper/form/multiselect'
import datepicker from '../helper/form/datePicker'
import textare from '../helper/form/textarea'
import inputo from '../helper/form/input'
import http from "../../plugins/https"
import apiroutes from "../../router/api-routes"

export default {
  name: 'Success',
  components: {
    PopupBase,
    buton,
    multiselect,
    textare,
    datepicker,
    inputo,
  },

  props: {
    activation: {
      type: Boolean,
      default: false
    },
    donne: {
      type: Object,
      default: null
    },
    solde: {
      type: Number,
      default: null
    }
  },

  data () {
    return {
      donnes: null,
      wantToMakeOffer: false,
      icons: {
        fermer,
        arrow,
        transfere,
        alerte
      },

      error: null,
      charge: false,
      optionDestinataire: [],
      payementType: ['Espèces', 'Chèques', 'Virement bancaire'],
      valuePayement: null,
      caisse: null,
      note: '',
      banque: [],
      response: {
        type: "MOBILE_MONEY",
        data: [
          {
            percent: 0.5,
            amount: 0
          }
        ],
        meta: {}
      },
    }
  },

  created() {
    this.wantToMakeOffer = this.activation
    this.getBank()
  },

  methods: {
    fermer () {
      this.$emit('oga', false)
    },

    getBank() {
      http.get(apiroutes.baseURL + apiroutes.allBank)
          .then(response => {
            this.charge = false
            console.log('bank')
            console.log(response)
            this.banque = response.banks
            for (let item in this.banque) {
              this.optionDestinataire.push(this.banque[item].bankName)
            }
          })
          .catch(error => {
            this.charge = false
            console.log(error)
          })
    },

    // Vérification
    async continuer() {
      if (this.response.date && this.response.data[0].amount > 0) {
          this.saveTransfer()
      } else {
        this.error = 'Veuillez remplir le formulaire pour continuer'
      }
    },

    async saveTransfer () {
      console.log('body')
      console.log(this.response)
      this.charge = true
      await http.post(apiroutes.baseURL + apiroutes.momoOnBank, this.response)
          .then(response => {
            this.charge = false
            console.log('success')
            console.log(response)
            this.$emit('oga', response.pdf)
          })
          .catch(error => {
            this.charge = false
            console.log(error)
          })
    },

    retourDestinataire (answer) {
      if (answer !== 'Destinataire'){
        for (let item in this.banque){
          if (this.banque[item].bankName === answer){
            this.response.bank = this.banque[item].id
          }
        }
      }
    },

    retourNote (answer) {
      this.response.note = answer
    },

    backDate (a) {
      this.response.date = a
    },

    backAmount (a) {
      if (a !== '' && a !== null) {
        this.response.data[0].amount = parseInt(a)
      }
    },

    backFrais (a) {
      this.response.data[0].percent = parseInt(a)
    },

    backRef (a) {
      this.response.proof = a
      this.response.depositNumber = a
    },

    logState (answer) {
      this.wantToMakeOffer = answer
      this.fermer()
    }
  }
}
</script>

<style lang="scss"  scoped>
@import "../../assets/styles/sass/mixin";
.success {
  z-index: 999;
}
.cardinal {
  width: 42%;
}

.moyens{
  background-color: #F5F5F5;
}

.success-container {
  font-family: $font-default;
  width: 100%;
  background-color: #F5F5F5;
  height: 100vh;
  margin: auto;
  border-radius: 12px;
}
.inputo{
  height: 40px;
  border: 1px solid #DCDCE4;
  border-radius: 4px;
}
.bloc{
  border-bottom: 1px solid #DDDDDD;
}
.bord{
  border-bottom: 1px dashed #000000
}

.bloc1{
  min-height: 40px;
  background: #FFF6EF;
  border-radius: 5px;
}
@media screen and (max-width: 700px){
  .success-container {
    width: 100%;
    height: 100vh;
  }
  .titre {
    font-size: 18px;
  }
  .bloc1 {
    width: 100%;
  }
  .bloc2 {
    width: 100%;
    font-size: 18px;
  }
  .button {
    width: 100%;
    font-size: 16px;
  }
  .cardinal {
    width: 100%;
  }
}

@media screen and (max-width: 1024px) and (min-width: 770px){
  .cardinal{
    width: 65%;
  }
  .titre{
    font-size: 18px;
  }
  .button{
    width: 100%;
    height: 55px;
    font-size: 19px;
  }
}

@media screen and (max-width: 769px) and (min-width: 701px){
  .cardinal{
    width: 90%;
  }
  .titre{
    font-size: 18px;
  }
  .button{
    width: 100%;
    height: 55px;
    font-size: 19px;
  }
}
@media screen and (max-width: 320px){
  .cardinal{
    width: 100%;
  }
  .titre{
    font-size: 14px;
  }
  .button{
    width: 100%;
    height: 55px;
    font-size: 15px;
  }
}
</style>
