<template>
  <transfere v-if="activeTransfere" :activation="activeTransfere" :donne="selectedItem" @oga="retourTransfere" />
  <billetage v-if="activeBillet" :donnes="selectedItem" :activation="activeBillet" @oga="retourBillet" />
  <cheques v-if="activeCheque" :donnes="selectedItem" :activation="activeCheque" @oga="retourCheque" />
  <update-transfert v-if="activUpdate" :activation="activUpdate" :donne="selectedItem" @oga="retourUpdate" />

  <div class="rounded-10 h-auto bg-white w-full pt-8 pb-8">
    <div class="mt-4">
      <div class="all">
        <div class="w-full flex text-90 text-c14 font-c5 text-left mb-6 pl-6">
          <div class="w-1/6">
            Date
          </div>
          <div class="w-1/6 ml-4">
            Montant
          </div>
          <div class="w-1/6 ml-4">
            Moyen de paiement
          </div>
          <div class="w-1/6 ml-4 text-left">
            Type d’opération
          </div>
          <div class="w-1/6 ml-4">
            Auteur
          </div>
          <div class="w-1/6 ml-4">
            Client/Motif
          </div>
          <div class="w-1/6 ml-4">
            Référence
          </div>
        </div>

        <div class="diviser" />

        <div
          v-if="charge"
          class="flex justify-center mt-16"
        >
          <easy-spinner
            type="dots"
            size="70"
          />
        </div>

        <div class="text-center text-c18 text-90 mt-10" v-if="historiqueData.length === 0 && !charge">
          Aucune donnée pour le moment
        </div>

        <div
            v-for="(item, index) in historiqueData"
            :key="index"
            class="pb-4 pt-5 pl-6 ope flex items-center"
            :class="{'transfere': item.action === 'TRANSFER_AMOUNT' || item.action === 'RECEIVE_TRANSFER', 'remise': item.action === 'DEPOSIT_IN_BANK'}"
        >
          <div @click="selected(item)" class="w-full">
            <liste
                class=""
                :item="item"
                :agence="agence"
            />
          </div>

          <icon
              v-if="role === 'SUPRA_ADMIN' || role === 'DIRECTION'"
              :data="icons.edit"
              height="35"
              width="35"
              class="icone cursor-pointer mr-4 edit"
              original
              @click="makeUpdate(item)"
          />
        </div>
      </div>
    </div>

    <div class="flex mt-10 justify-end pb-16 pr-10">
      <div class="w-1/3 flex items-center justify-end">
        <div class="text-c16 font-c6">
          Page {{ page }} sur {{ totalPage }}
        </div>
        <icon
            :data="icons.prev"
            height="50"
            width="50"
            class="icone cursor-pointer ml-4"
            original
            @click="prev"

        />
        <icon
            :data="icons.next"
            height="50"
            width="50"
            class="icone cursor-pointer ml-4"
            original
            @click="next"
        />
      </div>
    </div>
  </div>
</template>

<script>
import arrow from '../../../assets/icons/arrow.svg'
import agenda from '../../../assets/icons/agenda.svg'
import down from '../../../assets/icons/down.svg'
import prev from '../../../assets/icons/prev.svg'
import next from '../../../assets/icons/next.svg'
import liste from './liste'
import apiroutes from '../../../router/api-routes'
import http from '../../../plugins/https'
import transfere from '../../popup/transfere'
import billetage from '../../popup/billetage'
import cheques from '../../popup/chequeDetail'
import edit from '../../../assets/icons/update.svg'
import updateTransfert from '../../popup/updateTransfere'

export default {
  name: "Index",
  components: {
    liste,
    transfere,
    billetage,
    cheques,
    updateTransfert
  },

  data () {
    return {
      icons: {
        arrow,
        agenda,
        down,
        next,
        prev,
        edit
      },
      devis: [],
      charge: true,
      activeTransfere: false,
      activeBillet: false,
      info: null,
      historiqueData: [],
      selectedItem: null,
      activeCheque: false,
      agence: null,
      page: 1,
      limite: 20,
      total: 0,
      totalPage: 0,
      activUpdate: false,
      role: null
    }
  },

  created () {
    this.info = this.$store.getters.trackTemporal
    this.redirectControle()
    const admin = this.parseJwt(this.$store.getters.token)
    this.role = admin.type
  },

  methods: {
    prev () {
      if (this.page > 1) {
        this.page = this.page - 1
        this.redirectControle()
      }
    },

    retourUpdate (a) {
      if (a) {
        this.getHistorique()
      }

      this.activUpdate = false
    },

    parseJwt (token) {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      }).join(''));
      return JSON.parse(jsonPayload)
    },

    next () {
      if (this.totalPage > this.page) {
        this.page = this.page + 1
        this.redirectControle()
      }
    },

    redirectControle() {
      if (this.info.agency === null) {
        this.specialAgency()
      } else {
        this.agence = this.info.agency.label
        this.getHistorique()
      }
    },

    getHistorique () {
      console.log('jj')
      this.charge = true
      http.post(apiroutes.baseURL + apiroutes.agenceHistoriquePaginate, {
        page: this.page,
        limit: this.limite,
        id: this.info.id
      })
          .then(response => {
            this.charge = false
            console.log(response)
            this.controlePage(response)
          })
          .catch(error => {
            this.charge = false
            console.log(error)
          })
    },

    specialAgency () {
      const query = apiroutes.baseURL + apiroutes.specialAgenceParginate +
          '?id=' + this.$route.query.y + '&type=' + this.info.genre +
          '&page=' + this.page + '&limit=' + this.limite

      http.get(query)
          .then(response => {
            console.log('historique special')
            console.log(response)
            this.charge = false
            // alert('ok')
            this.controlePage(response)
          })
          .catch(error => {
            this.charge = false
            console.log(error)
          })
    },

    controlePage (response) {
      this.historiqueData = response.data
      this.total = response.total
      const page = parseInt(this.total)/parseInt(this.limite)

      if (page > parseInt(page)) {
        this.totalPage = parseInt(page) + 1
      } else {
        this.totalPage = parseInt(page)
      }
    },

    retourTransfere (answer) {
      if (answer) {
        if (this.selectedItem.method === 'CASH') {
          this.activeBillet = true
        } else {
          this.activeCheque = true
        }
      }
      this.activeTransfere = false
    },

    retourBillet (answer) {
      this.activeBillet = answer
      this.activeTransfere = true
    },

    retourCheque(answer) {
      this.activeCheque = answer
      this.activeTransfere = true
    },

    makeUpdate(item) {
      this.selectedItem = item
      this.activUpdate = true
    },

    selected (item) {
      this.selectedItem = item

      if (item.action === 'PAID_ORDER') {
        this.$router.push({ path: '/detailsCommande', query: { x: item.order.id } })
      } else if (item.action === 'FUND_CLIENT'){
        console.log('impossible')
      } else if (item.action === 'DEPOSIT_IN_BANK'){
        window.open(item.deposit.pdf, '_blank');

        if (item.deposit.justify_url){
          window.open(item.deposit.justify_url, '_blank');
        }
      } else if (item.action === 'RECEIVE_WITHDRAW_AMOUNT'){
        window.open(item.withdraw.pdf, '_blank');

        if (item.withdraw.proof){
          window.open(item.withdraw.proof, '_blank');
        }
      } else if (item.action === 'EXPENSE'){
        window.open(item.expense.pdf, '_blank');

        if (item.expense.payment_join_url) {
          window.open(item.expense.payment_join_url, '_blank');
        }

        if (item.expense.reference_join_url) {
          window.open(item.expense.reference_join_url, '_blank');
        }
      } else {
        this.activeTransfere = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.svg-icon{
  fill: transparent;
}
.filtr{
  background: rgba(242, 245, 249, 0.5);
  border: 0.5px solid #C4C4C4;
  box-sizing: border-box;
  border-radius: 5px;
  height: 36.39px;
}
.compFiltre{
  width: auto;
  min-width: 9.1%;
}
.diviser {
  height: 0.5px;
  background-color: #DDDDDD;
}
.transfere{
  background-color: #FFF5F5;
}
.remise{
  background-color: #F6F5FE;
}
.edit {
  display: none;
}
.ope:hover{
  .edit {
    display: block;
  }
}
</style>
