<template>
  <popupCharge :activation="charge" v-if="charge"/>
  <div class="p-10 pr-16 all">
    <div class="pt-20">
      <div
        class="flex items-center mt-6"
      >
        <div class="w-full flex text-left text-c36 items-center font-c6">
          <icon
            :data="icons.back"
            height="70"
            width="70"
            class="mr-2 cursor-pointer"
            @click="retour"
            original
          />
          <div class="ml-4 w-3/5">
            <span v-if="info.agency">{{ info.agency.label }}</span>
            <span v-if="info.department">
              <span v-if="info.department === 'ACCOUNTING'">COMPTABILITÉ</span>
              <span v-if="info.department === 'TECHNICIAN'">TECHNICIEN</span>
                <span v-if="info.department === 'DIRECTION'">DIRECTION</span>
            </span>
            <span v-if="info.person">{{info.person.fullname}}</span>
          </div>
        </div>

      </div>

      <div class="mt-6 pl-4">
        <stats :donnes="statData" v-if="statData !== null && info.agency !== null"/>

        <statsSpecial :donnes="statData" v-if="statData !== null && info.agency === null"/>

        <fiche class="mt-16"/>
      </div>
    </div>
  </div>
</template>

<script>
import back from '../../assets/icons/backBut.svg'
import stats from '../../component/cdevs/caisseDetail/stats'
import statsSpecial from '../../component/cdevs/caisseDetail/statsSpecial'
import fiche from '../../component/cdevs/caisseDetail/ficheCaisse'
import apiroutes from '../../router/api-routes'
import http from '../../plugins/https'
import popupCharge from '../../component/popup/popupCharge'

export default {
  name: "Index",

  components: {
    stats,
    fiche,
    popupCharge,
    statsSpecial
  },

  data () {
    return {
      icons: {
        back,
      },
      info: null,
      statData: null,
      charge: true,
    }
  },

  created () {
    this.info = this.$store.getters.trackTemporal
    console.log(this.info)
    if (this.info.agency === null) {
      this.specialAgency()
    } else {
      this.getStats()
    }
  },

  methods: {
    retour() {
      this.$router.push('/caisses')
    },

    getStats () {
      http.post(apiroutes.baseURL + apiroutes.agenceStats, {
        id: this.$route.query.y
      })
          .then(response => {
            console.log('stat')
            console.log(response)
            this.statData = response
            this.charge = false
          })
          .catch(error => {
            this.charge = false
            console.log(error)
          })
    },

    specialAgency () {
      http.get(apiroutes.baseURL + apiroutes.specialStat + '?id=' + this.$route.query.y)
          .then(response => {
            console.log('stat special')
            console.log(response)
            this.statData = response
            this.charge = false
          })
          .catch(error => {
            this.charge = false
            console.log(error)
          })
    },
  }
}
</script>

<style lang="scss" scoped>
.svg-icon {
  fill: transparent;
}
.all{
  min-height: 100vh;
}
</style>
