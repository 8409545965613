<template>
  <section class="success">
    <popup-base
        v-show="wantToMakeOffer"
        class="popo md:pt-10 md:pb-4 w-full"
        @clickOnBackground="logState"
    >
      <div class="cardinal mr-auto ml-auto p-5">
        <div
            class="success-container h-full pt-6"
        >
          <div class="text-left flex pl-6 pr-6">
            <div
                class="text-c24 w-4/5"
            >
              Chèques
            </div>
            <div class="w-1/5 flex justify-end">
              <icon
                  :data="icons.fermer"
                  height="30"
                  width="30"
                  class="cursor-pointer"
                  original
                  @click="fermer"
              />
            </div>
          </div>

          <div class="text-c18 font-c4 mt-6 bg-white p-6 pb-12">

            <div class="text-c14 mt-4 text-left bloc p-6">
              <div class="">
                <span class="text-yell">Nombre de chèques </span>
                <span class="">
                  : {{ donnes.transfer.data.length }}
                </span>
              </div>

              <div class="mt-4">
                <span class="text-yell">Montant</span>
                <span class="">
                  : {{ donnes.amount.toLocaleString() }} FCFA
                </span>
              </div>
            </div>

            <div class="mt-10 text-left">
              <div
                  class="bord pb-4 mt-4"
                  v-for="(item, index) in donnes.transfer.details"
                  :key="index"
              >
                <div class="flex">
                  <div class="w-2/3 text-c18"> Num :
                    <span v-if="!item.meta">-</span>
                    <span v-if="item.meta"> {{ item.meta.reference }} </span>
                  </div>
                  <div class="w-1/3 text-c16 text-oho-blue-clear text-right">{{ item.amount.toLocaleString() }} F</div>
                </div>

                <div class="flex mt-2 text-c11">
                  <div class="w-1/3 text-c4">
                    Banque : <span v-if="!item.meta">-</span> <span class="text-black" v-if="item.meta">{{ item.meta.banque }}</span>
                  </div>
                  <div class="w-1/3 text-c4">
                    Tireur : <span v-if="!item.meta">-</span>  <span class="text-black" v-if="item.meta">{{ item.meta.porteur }}</span>
                  </div>
                </div>
              </div>
            </div>

          </div>

          <div class="flex">
            <div class="w-full">
              <buton
                  label="Retour"
                  radius="0px 0px 10px 10px"
                  height="55px"
                  :charge="charge"
                  @oga="fermer"
              />
            </div>
          </div>
        </div>
      </div>
    </popup-base>
  </section>
</template>

<script>
import PopupBase from '../helper/add/popupfat'
import buton from '../helper/add/button'
import ic_agence from '../../assets/icons/agenceGray.svg'
import fermer from '../../assets/icons/fermer.svg'
import arrow from '../../assets/icons/arrow.svg'
import transfere from '../../assets/icons/transfere.svg'

export default {
  name: 'Success',
  components: {
    PopupBase,
    buton
  },

  props: {
    activation: {
      type: Boolean,
      default: false
    },
    donnes: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      wantToMakeOffer: false,
      icons: {
        ic_agence,
        fermer,
        arrow,
        transfere,
      },
      error: null,
      charge: false,
    }
  },

  created() {
    this.wantToMakeOffer = this.activation
  },

  methods: {
    fermer () {
      this.$emit('oga', false)
    },

    continuer () {

    },

    logState (answer) {
      this.wantToMakeOffer = answer
      this.fermer()
    },
  }
}
</script>

<style lang="scss"  scoped>
@import "../../assets/styles/sass/mixin";
.success {
  z-index: 999;
}
.cardinal {
  width: 42%;
}


.success-container {
  font-family: $font-default;
  width: 100%;
  background-color: #F5F5F5;
  height: auto;
  margin: auto;
  border-radius: 12px;
}

.bloc1{
  border: 0.832299px solid #ECECEC;
  box-sizing: border-box;
  border-radius: 6.65839px;
}

.bloc2 {
  border: 0.832299px dashed #CCCCCC;
  box-sizing: border-box;
  border-radius: 4.16149px;
}
.bord{
  border-bottom: 1px solid #ECECEC;
}

.bloc{
  min-height: 40px;
  background: #FFF6EF;
  border-radius: 5px;
}
@media screen and (max-width: 700px){
  .success-container {
    width: 100%;
    height: 100vh;
  }
  .titre {
    font-size: 18px;
  }
  .bloc1 {
    width: 100%;
  }
  .bloc2 {
    width: 100%;
    font-size: 18px;
  }
  .button {
    width: 100%;
    font-size: 16px;
  }
  .cardinal {
    width: 100%;
  }
}

@media screen and (max-width: 1024px) and (min-width: 770px){
  .cardinal{
    width: 65%;
  }
  .titre{
    font-size: 18px;
  }
  .button{
    width: 100%;
    height: 55px;
    font-size: 19px;
  }
}

@media screen and (max-width: 769px) and (min-width: 701px){
  .cardinal{
    width: 90%;
  }
  .titre{
    font-size: 18px;
  }
  .button{
    width: 100%;
    height: 55px;
    font-size: 19px;
  }
}
@media screen and (max-width: 320px){
  .cardinal{
    width: 100%;
  }
  .titre{
    font-size: 14px;
  }
  .button{
    width: 100%;
    height: 55px;
    font-size: 15px;
  }
}
</style>
