<template>
  <div class="text-left">
    <success :activation="activeSuccess" v-if="activeSuccess" :message="message" @oga="activeSuccess = false" :pdf="pdf"/>
    <depense :activation="activeDepense" v-if="activeDepense" :donne="donnes" @oga="backDepense"/>
    <momo-transfere :activation="activeMomo" :solde="donnes.mobileMoney" v-if="activeMomo" @oga="backMomo"/>
    <div class="flex mt-6">
      <div class="w-1/2 item bg-white p-6 flex items-center">
        <div class="w-1/2 mr-10">
          <div class="flex items-center">
            <icon
                :data="icons.wallet"
                height="35"
                width="35"
                class="cursor-pointer"
                original
            />

            <div class="ml-4">
              <div class="text-c14">Caisse recette</div>
              <div class="text-c18 font-c5">
                {{ refactorAmount(donnes.cash + donnes.bankCheck, '.') }}
                <span class="text-d">FCFA</span>
              </div>
            </div>
          </div>

          <div class="mt-6">
            <div class="flex">
              <div class="w-1/3 text-c11 text-90">Espèces</div>
              <div class="w-2/3 font-c6 text-c13 text-right">
                {{ refactorAmount(donnes.cash, '.') }} FCFA
              </div>
            </div>

            <div class="flex mt-6">
              <div class="w-1/3 text-c11 text-90">Chèques</div>
              <div class="w-2/3 font-c6 text-c13 text-right">
                {{ refactorAmount(donnes.bankCheck, '.') }} FCFA
              </div>
            </div>
          </div>
        </div>

        <div class=" w-1/2" v-if="donnes.wallet.department !== 'ACCOUNTING'">
          <div v-if="donnes.wallet.person.user.type !== 'ACCOUNTING'">
            <div
                class="h-45 rounded-10 text-white text-c14 flex justify-center items-center cursor-pointer bg-oho-green"
                @click="transferer('/transfere')"
            >
              <div>Transfert de caisse</div>
              <icon
                  :data="icons.arrow"
                  height="20"
                  width="20"
                  class="cursor-pointer ml-4"
                  color="#fff"
                  original
              />
            </div>

            <div
                class="h-45 mt-6 rounded-10 text-white text-c14 flex justify-center items-center cursor-pointer bg-oho-blue"
                @click="goto('/bankRemise')"
            >
              <div class="text-white">Remise en banque</div>
              <icon
                  :data="icons.arrow"
                  height="20"
                  width="20"
                  class="cursor-pointer ml-4"
                  color="#fff"
                  original
              />
            </div>
          </div>

          <div v-if="donnes.wallet.person.user.type === 'ACCOUNTING'">
            <div
                class="mt-6"
            >
              <buton label="Remise en banque" height="45px" background="#c4c4c4" size="14px" radius="10px" />
            </div>

            <div
                class="mt-6"
            >
              <buton label="Remise en banque" height="45px" background="#c4c4c4" size="14px" radius="10px" />
            </div>
          </div>
        </div>

        <div class=" w-1/2" v-if="donnes.wallet.department === 'ACCOUNTING'">
          <div class="h-45">
            <operation class="h-full"/>
          </div>

          <div
              class="h-45 mt-6 rounded-10 text-oho-blue-clear text-c14 flex justify-center items-center cursor-pointer bg-violet-clear"
            @click="activeDepense = true"
          >
            <icon
                :data="icons.add"
                height="23"
                width="23"
                class="cursor-pointer"
                original
            />
            <div class="ml-2">Enregistrer une dépense</div>
          </div>
        </div>
      </div>

      <div class="w-1/2 ml-5">

        <div class="w-full flex ml-4">
          <div class="w-1/2 item bg-white p-6">
            <div class="flex items-center">
              <icon
                  :data="icons.mobile"
                  height="35"
                  width="35"
                  class="cursor-pointer"
                  original
              />

              <div class="ml-4">
                <div class="text-c14">Caisse Mobile Money</div>
                <div class="text-c18 font-c5">
                  {{ refactorAmount(donnes.mobileMoney, '.') }}
                  <span class="text-d">FCFA</span></div>
              </div>
            </div>

            <div class="h-1 bg-d mt-2 w-full"></div>

            <div class="mt-6">
              <div
                  class="h-45 rounded-10 text-white text-c14 flex justify-center items-center cursor-pointer bg-90"
                  v-if="donnes.wallet.department !== 'ACCOUNTING'"
              >
                <div>Transfert MoMo</div>
                <icon
                    :data="icons.arrow"
                    height="20"
                    width="20"
                    class="cursor-pointer ml-4"
                    color="#ffffff"
                    original
                />
              </div>

              <div
                  v-if="donnes.wallet.department === 'ACCOUNTING'"
              >
                <buton label="Transfert MoMo en banque" height="45px" size="14px" radius="10px" @info="activeMomo = true"/>
              </div>
            </div>
          </div>


          <div class="w-1/2 ml-6 item degrad p-4">
            <div class="flex items-center">
              <div class="text-c12 text-yell font-c6 w-2/3">Historique des transferts et remises</div>

<!--              <div class="w-1/3 text-c10 underline cursor-pointer" @click="historique">Tout afficher</div>-->
            </div>

            <div class="h-1 bg-d mt-4 w-full"></div>

            <div class="mt-4">
              <div class="">
                <div class="flex">
                  <div class="w-1/2 text-c11 text-90">Espèces</div>
                  <div class="w-1/2 font-c6 text-c13 text-right">{{ donnes.transferCashCount }}</div>
                </div>

                <div class="flex mt-2">
                  <div class="w-1/2 text-c11 text-90">Chèques</div>
                  <div class="w-1/2 font-c6 text-c13 text-right">{{ donnes.transferBankCount }}</div>
                </div>

                <div class="flex mt-2">
                  <div class="w-1/2 text-c11 text-90">Mobile Money</div>
                  <div class="w-1/2 font-c6 text-c13 text-right">{{ donnes.transferMomoCount }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import wallet from '../../../assets/icons/wallet.svg'
import mobile from '../../../assets/icons/mobile.svg'
import arrow from '../../../assets/icons/arrow.svg'
import add from '../../../assets/icons/addSquare.svg'
import operation from './operationCaisse'
import buton from '../../helper/add/button'
import depense from '../../popup/depense'
import momoTransfere from '../../popup/momoOnBanque'
import success from '../../popup/success'

export default {
  name: "Index",
  components: {
    operation,
    buton,
    depense,
    success,
    momoTransfere
  },
  props: {
    donnes: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      icons: {
        wallet,
        arrow,
        mobile,
        add
      },
      activeDepense: false,
      activeSuccess: false,
      pdf: null,
      activeMomo: false,
      message: ''
    }
  },

  created () {
    console.log('oiisidfj')
    console.log(this.donnes)
  },

  methods: {
    transferer (index) {
      this.$store.dispatch('saveTemporalItem', this.donnes.wallet)
      this.$store.dispatch('saveWallet', this.donnes)
      this.$router.push({ path: index, query: { x: this.donnes.wallet.id } })
    },

    backDepense (answer) {
      if (answer !== false) {
        this.message = 'Dépense enregistré avec succès'
        this.pdf = answer
        this.activeSuccess = true

      }
      this.activeDepense = false
    },

    backMomo (answer) {
      if (answer !== false) {
        this.message = 'Transfert de fonds enregistré avec succès'
        this.pdf = answer
        this.activeSuccess = true
      }
      this.activeMomo = false
    },

    goToDetail (){
      this.$router.push('/caisseDetail')
    },

    historique () {
      this.$router.push({ path: '/historique', query: { x: this.donnes.wallet.id } })
    },

    goToTransfere (index) {
      this.$store.dispatch('saveTemporalItem', this.donnes.wallet)

      this.$router.push(index)
    },

    goto (index) {
      this.$router.push(index)
    },

    refactorAmount (a, b) {
      a = '' + a;
      b = b || ' ';
      let c = '',
          d = 0;
      while (a.match(/^0[0-9]/)) {
        a = a.substr(1);
      }
      for (let i = a.length-1; i >= 0; i--) {
        c = (d !== 0 && d % 3 === 0) ? a[i] + b + c : a[i] + c;
        d++;
      }
      return c;
    },
  },
}
</script>

<style lang="scss" scoped>
.svg-icon{
  fill: transparent;
}
.item{
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}
.degrad {
  background: linear-gradient(180deg, #F6E6C6 0%, rgba(251, 167, 5, 0) 100%);
}
</style>
