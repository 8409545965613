<template>
  <div class="text-left w-auto">
    <div
        class="h-full bg-oho-blue-clear flex items-center justify-center rounded-10 pl-3 pr-3"
        @click="activeOption =! activeOption"
    >
      <div class="text-white text-13">
        Enregistrer une operation
      </div>
      <icon
          :data="icons.down"
          height="15"
          width="15"
          class="ml-3 cursor-pointer"
          color="white"
          original
      />
    </div>
    <div
        class="bg-white rounded-5 w-1/6 p-4 text-c14 absolute mt-2"
        v-if="activeOption"
        @click="activeOption = false"
    >
      <div class="pt-4 ite pb-4 pl-4" @click="goto('/transfereDeCaisse')">Transferts de caisse</div>
      <div class="h-1 bg-f5 w-full"/>
      <div class="pt-4 ite pb-4 pl-4" @click="goto('/bankRemise', null)">Remise en banque</div>
      <div class="h-1 bg-f5 w-full"/>
    </div>
  </div>
</template>

<script>
import down from '../../../assets/icons/down.svg'

export default {
  name: "Index",
  components: {
  },
  props: {
    donnes: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      icons: {
        down
      },
      activeOption: false
    }
  },

  methods: {
    goto (index) {
      // this.$store.dispatch('saveTemporalItem', this.donnes)
      this.$router.push(index)
      // this.$store.dispatch('saveVirement', virement)
    }
  },
}
</script>

<style lang="scss" scoped>
.svg-icon{
  fill: transparent;
}
.absolute{
  box-shadow: 0px 1px 28px rgba(0, 0, 0, 0.12);
  position: relative;
  width: 100%;
}
.ite:hover {
  background-color: #e7effd;
  cursor: pointer;
}
</style>
