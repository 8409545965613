<template>
  <section class="success">
    <popup-base
        v-show="wantToMakeOffer"
        class="popo md:pt-10 w-full"
        @clickOnBackground="logState"
    >
      <div class="cardinal mr-auto ml-auto p-5">
        <div
            class="success-container h-full pt-6"
        >
          <div class="text-left flex pl-6 pr-6">
            <div
                class="text-c24 w-4/5"
            >
              Modifier une opération
            </div>
            <div class="w-1/5 flex justify-end">
              <icon
                  :data="icons.fermer"
                  height="30"
                  width="30"
                  class="cursor-pointer"
                  original
                  @click="fermer"
              />
            </div>
          </div>

          <div class="text-c18 font-c4 mt-6 bg-white p-6 round">

            <div class="text-left">
              <div class="text-c14 mb-2">Montant</div>
              <inputo type="Number" :value-t="donnes.amount" @info="backAmount"/>

              <div class="text-c14 mt-4 mb-2">Moyen de paiement</div>
              <multiselect
                  background="#fff"
                  :option="optionMoyen"
                  v-if="optionMoyen.length > 0"
                  @info="retourMoyen"
                  :value-t="donnes.method"
              />

              <div class="text-c14 mt-4 mb-2">Note</div>
              <textareaa
                  background="#fff"
                  radius="5px"
                  @info="backNote"
              />
            </div>

            <div
                v-if="error !== null"
                class="mt-6 text-red text-c13"
            >
              {{ error }}
            </div>

            <div class="flex mt-6">
              <buton
                  label="Fermer"
                  radius="5px"
                  background="#ECECEC"
                  color="black"
                  height="50px"
                  :charge="charge"
                  class="w-1/2"
                  @oga="fermer"
              />

              <buton
                  label="Enregistrer"
                  radius="5px"
                  height="50px"
                  background="#5138EE"
                  :charge="charge"
                  class="w-1/2 ml-6"
                  @info="saveData"
              />
            </div>
          </div>
        </div>
      </div>
    </popup-base>
  </section>
</template>

<script>
import PopupBase from '../helper/add/popupfat'
import buton from '../helper/add/button'
import ic_agence from '../../assets/icons/agenceGray.svg'
import fermer from '../../assets/icons/fermer.svg'
import arrow from '../../assets/icons/arrow.svg'
import transfere from '../../assets/icons/transfere.svg'
import document from '../../assets/icons/document.svg'
import inputo from '../helper/form/input'
import multiselect from '../helper/form/multiselect'
import textareaa from '../helper/form/textarea'
import http from "../../plugins/https"
import apiroutes from "../../router/api-routes"

export default {
  name: 'Success',
  components: {
    PopupBase,
    buton,
    inputo,
    multiselect,
    textareaa
  },

  props: {
    activation: {
      type: Boolean,
      default: false
    },
    donne: {
      type: Object,
      default: null
    },
    estimation: {
      type: String,
      default: null
    }
  },

  data () {
    return {
      donnes: null,
      wantToMakeOffer: false,
      icons: {
        ic_agence,
        fermer,
        arrow,
        transfere,
        document
      },
      error: null,
      charge: false,
      optionMoyen: ['Espèce', 'Chèque', 'Mobile Money', 'Wallet du client'],
      response: {},
    }
  },

  created() {
    this.donnes = this.donne
    this.wantToMakeOffer = this.activation
    console.log('donnes', this.donnes)
  },

  methods: {
    fermer () {
      this.$emit('oga', false)
    },

    backNote (a) {
      this.response.note = a
    },

    backAmount (a) {
      this.response.amount = parseInt(a)
    },

    retourMoyen(a) {
      if (a === 'Espèce') {
        this.response.method = 'CASH'
      }
      if (a === 'Chèque') {
        this.response.method = 'BANK_CHECK'
      }
      if (a === 'Mobile Money') {
        this.response.method = 'MOBILE_MONEY'
      }
      if (a === 'Wallet du client') {
        this.response.method = 'CLIENT_ASSETS'
      }
    },

    logState (answer) {
      this.wantToMakeOffer = answer
      this.fermer()
    },

    saveData () {
      this.response.id = this.donnes.id
      this.error = null
      if (this.response.note) {
        this.charge = true
         http.post(apiroutes.baseURL + apiroutes.updateTransfert, this.response)
            .then(response => {
              this.$emit('oga', true)
              console.log(response)
            })
            .catch(error => {
              console.log(error)
              this.error = 'Nous avons rencontrer un problème'
            })
      } else {

        this.error = 'Veuillez entrer la raison de cette modification pour continuer'
      }
    },
  }
}
</script>

<style lang="scss"  scoped>
@import "../../assets/styles/sass/mixin";
.success {
  z-index: 999;
}
.cardinal {
  width: 42%;
}
.diviser {
  border-bottom: 1px dashed #606060;
}
.moyens {
  background-color: #F5F5F5;
}

.round {
  border-radius: 0px 0px 12px 12px;
}

.success-container {
  font-family: $font-default;
  width: 100%;
  background-color: #F5F5F5;
  height: auto;
  margin: auto;
  border-radius: 12px;
}

.bloc1{
  border: 0.832299px solid #ECECEC;
  box-sizing: border-box;
  border-radius: 6.65839px;
}

.bloc2 {
  border: 0.832299px dashed #CCCCCC;
  box-sizing: border-box;
  border-radius: 4.16149px;
}

.bloc{
  min-height: 40px;
  background: #FFF6EF;
  border-radius: 5px;
}
@media screen and (max-width: 700px){
  .success-container {
    width: 100%;
    height: 100vh;
  }
  .titre {
    font-size: 18px;
  }
  .bloc1 {
    width: 100%;
  }
  .bloc2 {
    width: 100%;
    font-size: 18px;
  }
  .button {
    width: 100%;
    font-size: 16px;
  }
  .cardinal {
    width: 100%;
  }
}

@media screen and (max-width: 1024px) and (min-width: 770px){
  .cardinal{
    width: 65%;
  }
  .titre{
    font-size: 18px;
  }
  .button{
    width: 100%;
    height: 55px;
    font-size: 19px;
  }
}

@media screen and (max-width: 769px) and (min-width: 701px){
  .cardinal{
    width: 90%;
  }
  .titre{
    font-size: 18px;
  }
  .button{
    width: 100%;
    height: 55px;
    font-size: 19px;
  }
}
@media screen and (max-width: 320px){
  .cardinal{
    width: 100%;
  }
  .titre{
    font-size: 14px;
  }
  .button{
    width: 100%;
    height: 55px;
    font-size: 15px;
  }
}
</style>
